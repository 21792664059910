import { useContext, useEffect, useState } from "react";
import { InputContext } from "../contexts/InputContext";
import { StepContext } from "../contexts/StepContext";
import { facilities } from "../products";
import "../styles/BookingHeader.css";
import t from "../locales/sv.json";
import arrowIcon from "../assets/svgs/RightArrow.svg";
import location from "../assets/svgs/location-line.svg";
import calendar from "../assets/svgs/CalendarIcon.svg";
import {
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BookingHeader = () => {
  const { facility, startDate, endDate, startTime, endTime } =
    useContext(InputContext);
  const [currentFacility, setCurrentFacility] = useState("");
  const { step: currentStep } = useContext(StepContext);

  useEffect(() => {
    setCurrentFacility(
      facility ? facilities.find(f => f.name === facility) : ""
    );
  }, [facility]);

  return (
    <div className="bookingHeaderContainer">
      <h4>Online bokning</h4>
      <h1 className="bookingHeaderTitle">
        {facility ? facility : t.bookingHeader.chooseFacility}
      </h1>
      {currentFacility && (
        <Accordion
          square
          disableGutters
          defaultExpanded
          className="choicesContainer"
        >
          <AccordionSummary
            className="choicesSummary"
            expandIcon={<ExpandMoreIcon />}
          >
            <p>{t.common.details}</p>
          </AccordionSummary>
          <AccordionDetails className="choicesDetails">
            <div>
              <div className="addressContainer">
                <img src={location} className="locationPin" alt="" />
                <p>{`${currentFacility.address}, ${currentFacility.zipCode} ${currentFacility.city}`}</p>
              </div>
              <Link
                href={currentFacility.link}
                target="_blank"
                rel="noreferrer"
                className="facilityLink"
              >
                {t.bookingHeader.carPark}
              </Link>
            </div>
            {startDate &&
              endDate &&
              startTime &&
              endTime &&
              currentStep > 1 && (
                <div className="datesContainer">
                  <div className="startDateContainer">
                    <img className="calendarIcon" src={calendar} alt=""></img>
                    <span>{`${new Date(startDate)
                      .toLocaleString("sv")
                      .substring(0, 10)}, ${startTime}`}</span>
                  </div>
                  <img alt="->" className="arrowIcon" src={arrowIcon}></img>
                  <div className="endDateContainer">
                    <img className="calendarIcon" src={calendar} alt=""></img>
                    <span>{`${new Date(endDate)
                      .toLocaleString("sv")
                      .substring(0, 10)}, ${endTime}`}</span>
                  </div>
                </div>
              )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default BookingHeader;
