import dayjs from "dayjs";

const hourString = hourInt => {
  if (hourInt < 10) {
    return "0" + hourInt.toString();
  } else return hourInt.toString();
};

export const isValidTime = time => {
  const regEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
  return time.match(regEx);
};

export const roundMinutes = time => {
  const minutes = parseInt(time.split(":")[1]);
  const hours = parseInt(time.split(":")[0]);
  switch (true) {
    case minutes > 0 && minutes < 8:
      return hourString(hours) + ":00";
    case minutes >= 8 && minutes < 22:
      return hourString(hours) + ":15";
    case minutes >= 22 && minutes < 38:
      return hourString(hours) + ":30";
    case minutes >= 38 && minutes < 52:
      return hourString(hours) + ":45";
    case minutes >= 52:
      if (hours === 23) {
        return "00:00";
      }
      return hourString(hours + 1) + ":00";
    default:
      return hourString(hours) + ":00";
  }
};

export const getCorrectTime = (date, time) => {
  const hours = parseInt(time.split(":")[0]);
  const minutes = parseInt(time.split(":")[1]);
  return dayjs(date).hour(hours).minute(minutes).second(0).millisecond(0);
};

export const isAfterAllowedTime = (startDate, endTime) => {
  // Allowed time to book fixed pmc ends 15 minutes before pmc start time
  const hours = parseInt(endTime.split(":")[0]);
  return (
    dayjs(startDate).isSame(dayjs(), "day") &&
    dayjs(dayjs()).isAfter(
      dayjs()
        .set("hour", hours - 1)
        .set("minute", 45)
    )
  );
};
