import { useEffect, useState } from "react";
import axios from "axios";

function useApi() {
  const [error, setError] = useState("");
  useEffect(() => {}, []);

  async function getAvailability(pmcId, startDate, endDate) {
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();
    try {
      const result = await axios.get(
        `${window.REACT_APP_SERVER_URL}/availability?pmcId=${pmcId}&startDate=${startDateString}&endDate=${endDateString}`
      );
      return result.data;
    } catch (e) {
      setError(e.response);
      return 0;
    }
  }

  async function getFlexiblePrice(pmcId, startDate, endDate) {
    const data = {
      pmcId: pmcId.toString(),
      startTime: startDate,
      endTime: endDate,
    };
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/flexiblePrice`,
        data
      );
      return result.data;
    } catch (e) {
      setError(e.response);
      return 0;
    }
  }

  async function getFixedPrice(pmcId) {
    try {
      const result = await axios.get(
        `${window.REACT_APP_SERVER_URL}/flexiblePrice?${pmcId}`
      );
      return result.data;
    } catch (e) {
      setError(e.response);
      return 0;
    }
  }

  async function lockBooking(pmcId, customer, registrationPlates, validity) {
    const data = {
      pmcIdRef: pmcId,
      customer: customer,
      registrationPlates: registrationPlates,
      validity: validity,
    };
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/permit`,
        data
      );
      return result.data;
    } catch (e) {
      setError(e.response);
      if (e.response.data.status && e.response.data.status === 409) {
        return 409;
      }
    }
  }

  async function activatePermit(permitId) {
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/permit/activate?permitId=${permitId}`
      );
      return result.data;
    } catch (e) {
      setError(e.response);
    }
  }

  async function getPermit(permitId) {
    try {
      const result = await axios.get(
        `${window.REACT_APP_SERVER_URL}/permit/${permitId}`
      );
      return result.data;
    } catch (e) {
      setError(e.response);
      return 0;
    }
  }

  async function registerPayment(
    pmcId,
    startTime,
    endTime,
    discountCode,
    parkingZoneId,
    isFlexible,
    facility,
    startDate
  ) {
    const data = {
      discount: discountCode,
      parkingZoneId: parkingZoneId,
      pricingInfo: {
        pmcId: pmcId,
        startTime: startTime,
        endTime: endTime,
      },
      isFlexible: isFlexible,
      facility,
      startDate,
    };
    const result = await axios.post(
      `${window.REACT_APP_SERVER_URL}/payment/register`,
      data
    );
    return result.data;
  }

  async function processPayment(transactionId, permitId) {
    const data = { permitId: permitId, transactionId: transactionId };
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/payment/process`,
        data
      );
      return { data: result.data, error: "", status: 201 };
    } catch (e) {
      if (e.response.status === 503) {
        return { data: "", error: e.response.data.message, status: 503 };
      } else {
        return { data: "", error: e.response.data.message, status: 500 };
      }
    }
  }

  async function processInternalBooking(
    discountCode,
    permitId,
    facility,
    startDate
  ) {
    const data = {
      discount: discountCode,
      permitId: permitId,
      facility: facility,
      startDate: startDate,
    };
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/payment/internal`,
        data
      );
      return { data: result.data, error: "", status: 201 };
    } catch (e) {
      if (e.response.status === 403) {
        return { data: "", error: e.response.data.message, status: 403 };
      } else {
        return { data: "", error: e.response.data.message, status: 500 };
      }
    }
  }

  async function creditPayment(transactionId, permitId) {
    const data = { permitId: permitId, transactionId: transactionId };
    try {
      const result = await axios.post(
        `${window.REACT_APP_SERVER_URL}/payment/credit`,
        data
      );
      return result.data;
    } catch (e) {
      return 0;
    }
  }

  async function verifyCampaignCode(code, facility, startDate) {
    const result = await axios.get(
      `${window.REACT_APP_SERVER_URL}/campaign?code=${code}&facility=${facility}&startDate=${startDate}`
    );
    return result.data;
  }

  async function sendConfirmationEmail(
    email,
    firstName,
    facility,
    address,
    description,
    garageDescription,
    product,
    startTime,
    endTime,
    startDate,
    endDate,
    totalAmount,
    licensePlate,
    transactionId,
    permitId,
    orderNumber
  ) {
    const result = await axios.post(
      `${window.REACT_APP_SERVER_URL}/email?type=confirmation`,
      {
        email,
        firstName,
        facility,
        address,
        description,
        garageDescription,
        product,
        startTime,
        endTime,
        startDate,
        endDate,
        totalAmount,
        licensePlate,
        transactionId,
        permitId,
        orderNumber,
      }
    );
    if (result.status === 202) {
      return true;
    }
    return false;
  }

  async function sendCancellationEmail(
    email,
    firstName,
    facility,
    startDate,
    endDate
  ) {
    const result = await axios.post(
      `${window.REACT_APP_SERVER_URL}/email?type=cancellation`,
      {
        email,
        firstName,
        facility,
        startDate,
        endDate,
      }
    );
    if (result.status === 202) {
      return true;
    }
    return false;
  }

  return {
    error,
    getAvailability,
    getFlexiblePrice,
    getFixedPrice,
    lockBooking,
    registerPayment,
    processPayment,
    processInternalBooking,
    creditPayment,
    verifyCampaignCode,
    sendConfirmationEmail,
    sendCancellationEmail,
    activatePermit,
    getPermit,
  };
}

export default useApi;
