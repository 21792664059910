import { useContext } from "react";
import { StepContext } from "../contexts/StepContext";
import "../styles/ProgressStep.css";
import t from "../locales/sv.json";

const ProgressStep = () => {
  const { step } = useContext(StepContext);
  return (
    <div className="progressStepContainer">
      <div
        className={`stepContainer ${
          step === 1 ? "current" : step > 1 ? "past" : ""
        }`}
      >
        <p>{t.progressStep.step1}</p>
        <span></span>
      </div>
      <div
        className={`stepContainer ${
          step === 2 ? "current" : step > 2 ? "past" : ""
        }`}
      >
        <p>{t.progressStep.step2}</p>
        <span></span>
      </div>
      <div
        className={`stepContainer ${
          step === 3 ? "current" : step > 3 ? "past" : ""
        }`}
      >
        <p>{t.progressStep.step3}</p>
        <span></span>
      </div>
      <div className={`stepContainer ${step === 4 ? "current" : ""}`}>
        <p>{t.progressStep.step4}</p>
        <span></span>
      </div>
    </div>
  );
};

export default ProgressStep;
