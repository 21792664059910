import dayjs from "dayjs";

const timeOutInterval = 900000;

export const startTimer = () => {
  sessionStorage.setItem("sessionStartTime", dayjs());
};

export const isTimedOut = () => {
  const startTime = sessionStorage.getItem("sessionStartTime");
  const diff = dayjs().diff(dayjs(startTime));
  return diff > timeOutInterval;
};
