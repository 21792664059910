import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import t from "../locales/sv.json";
import calendarIcon from "../assets/svgs/CalendarIcon.svg";
import arrowIcon from "../assets/svgs/RightArrow.svg";
import "../styles/Cancellation.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";

const Cancellation = () => {
  const { getPermit, creditPayment, sendCancellationEmail } = useApi();
  const [bookingDetails, setBookingDetails] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [alert, setAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transactionId");
  const permitId = searchParams.get("permitId");

  const navigate = useNavigate();

  useEffect(() => {
    const getBookingDetails = async () => {
      const details = await getPermit(permitId);
      setBookingDetails(details);
    };
    getBookingDetails();
  }, [permitId]);

  const cancelBooking = async () => {
    setAlert(false);
    setCancelled(true);
    const creditedResult = await creditPayment(transactionId, permitId);
    if (!creditedResult) {
      navigate("/order/cancellationConfirmed", {
        state: { error: "Could not credit payment" },
      });
      return;
    }
    await sendCancellationEmail(
      bookingDetails.customer.email,
      bookingDetails.customer.name,
      bookingDetails.product,
      bookingDetails.startDate,
      bookingDetails.endDate
    );
    navigate("/order/cancellationConfirmed", { state: { error: "" } });
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  return (
    <div className="cancellationContainer">
      <h1>{t.cancellation.title}</h1>
      {!transactionId ? (
        <div>
          <p>
            {t.cancellation.invalid}. {t.cancellation.instructions}
          </p>
        </div>
      ) : bookingDetails ? (
        <>
          <h3>{t.cancellation.booking}:</h3>
          <p className="upper">{t.common.facility}:</p>
          <p className="lower">{bookingDetails.product}</p>
          <p className="upper">{t.personalInformation.email}:</p>
          <p className="lower">{bookingDetails.customer.email}</p>

          <p className="upper">Bokad period</p>
          <div className="datesContainer">
            <div className="fromContainer">
              <img alt="" src={calendarIcon} />
              <p>{t.common.from}:</p>
              <p>
                {bookingDetails.startDate.split("T")[0]},{" "}
                {bookingDetails.startDate.split("T")[1].substring(0, 5)}
              </p>
            </div>
            <img className="arrowIcon" alt="->" src={arrowIcon}></img>
            <div className="toContainer">
              <img alt="" src={calendarIcon} />
              <p>{t.common.to}:</p>
              <p>
                {bookingDetails.endDate.split("T")[0]},{" "}
                {bookingDetails.endDate.split("T")[1].substring(0, 5)}
              </p>
            </div>
          </div>
          {!cancelled ? (
            dayjs(bookingDetails.startDate).isBefore(dayjs()) ? (
              <div>
                <p>
                  Startdatumet för denna bokning har redan passerat och
                  bokningen kan därför inte avbokas.
                </p>
              </div>
            ) : (
              <div className="submitContainer confirmCancelContainer">
                <Button
                  className="cancelBookingButton"
                  onClick={() => setAlert(true)}
                >
                  Avboka
                </Button>
              </div>
            )
          ) : (
            <>
              <h3>Vi behandlar din förfrågan</h3>
              <div className="loader"></div>
            </>
          )}
          <Dialog open={alert} onClose={handleCloseAlert}>
            <DialogTitle className="cancelDialogTitle">
              {t.cancellation.alertTitle}
            </DialogTitle>
            <DialogContent>{t.cancellation.alertText}</DialogContent>
            <DialogActions>
              <div className="submitContainer cancelButtonsContainer">
                <Button onClick={handleCloseAlert}>
                  {t.cancellation.goBack}
                </Button>
                <Button className="confirmCancelButton" onClick={cancelBooking}>
                  {t.cancellation.cancel}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : bookingDetails === 0 ? (
        <p>
          {t.cancellation.couldNotRetrieve} {t.cancellation.instructions}
        </p>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default Cancellation;
