import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { StepContext } from "../contexts/StepContext";
import ConfirmedAnimation from "./ConfirmedAnimation";
import FailedAnimation from "./FailedAnimation";
import "../styles/FinalInformation.css";
import t from "../locales/sv.json";
import { Divider } from "@mui/material";

const FinalInformation = () => {
  const { setStep } = useContext(StepContext);
  const { state } = useLocation();
  const {
    error,
    status,
    facility,
    address,
    startTime,
    endTime,
    startDate,
    endDate,
    name,
    email,
    licensePlate,
    orderNumber,
  } = state;

  useEffect(() => {
    setStep(4);
  }, []);
  return (
    <div className="finalInformationContainer">
      {error ? (
        <div className="failedPaymentContainer">
          <FailedAnimation />
          <h2>{t.finalInformation.failed}</h2>
          {status === 503 ? (
            <p>{t.finalInformation.paymentFailed}</p>
          ) : status === 500 ? (
            <p>{t.finalInformation.permitFailed}</p>
          ) : (
            <p>{error}</p>
          )}
        </div>
      ) : (
        <>
          <ConfirmedAnimation />
          <h2>{t.finalInformation.thankYou}</h2>

          <p>{t.finalInformation.confirmationText}</p>
          <p>
            {t.finalInformation.orderNumber}
            <span className="orderNumber">{orderNumber}</span>
          </p>
          <Divider className="addMargin" />
          <div className="messageContainer">
            <div className="bookingDetailsContainer">
              <h3>{t.common.details}</h3>
              <p>
                {t.common.facility}: {facility}
              </p>
              <p>
                {t.common.address}: {address}
              </p>
              <p>
                {t.common.from}: {new Date(startDate).toLocaleDateString("sv")},{" "}
                {startTime}
              </p>
              <p>
                {t.common.to}: {new Date(endDate).toLocaleDateString("sv")},{" "}
                {endTime}
              </p>
            </div>
            <div className="bookerDetailsContainer">
              <h3>{t.finalInformation.bookedBy}</h3>
              <p>
                {t.common.name}: {name}
              </p>
              <p>
                {t.common.email}: {email}
              </p>
              <p>
                {t.common.regPlate}: {licensePlate}
              </p>
            </div>
          </div>
        </>
      )}
      <div className="homeLinkContainer">
        <Link to="/" className="homeLink" onClick={() => setStep(1)}>
          {t.finalInformation.back}
        </Link>
      </div>
    </div>
  );
};

export default FinalInformation;
