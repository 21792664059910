import React from "react";
import { Outlet } from "react-router-dom";
import AimoHeader from "../components/AimoHeader";
import BookingHeader from "../components/BookingHeader";
import Footer from "../components/Footer";
import ProgressStep from "../components/ProgressStep";
import { StepProvider } from "../contexts/StepContext";
import "../App.css";
import { StyledEngineProvider } from "@mui/material";

function InitialLayout() {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <StepProvider>
          <div className="flexWrapper">
            <div>
              <AimoHeader />
              <BookingHeader />
              <ProgressStep />
              <Outlet />
            </div>
            <Footer />
          </div>
        </StepProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default InitialLayout;
