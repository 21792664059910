import React, { useContext, useState } from "react";
import { InputContext, InputDispatchContext } from "../contexts/InputContext";
import FlexBookingPicker from "./FlexBookingPicker";
import errorIcon from "../assets/svgs/errorIcon.svg";
import { getCorrectTime, isAfterAllowedTime } from "../services/timeService";
import { startTimer } from "../services/timeOutService";
import useApi from "../hooks/useApi";
import t from "../locales/sv.json";
import dayjs from "dayjs";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const ProductList = ({ products, flexProduct, fixedProductEndTime }) => {
  const { product, startDate, availability } = useContext(InputContext);
  const { setProduct, setPrice, setAvailability, setEndDate } =
    useContext(InputDispatchContext);
  const { getAvailability } = useApi();
  const [flexPrice, setFlexPrice] = useState();

  const handleProductChange = async e => {
    if (flexProduct && e.target.value === flexProduct.name) {
      setAvailability(null);
      setPrice(flexPrice);
    } else {
      setFlexPrice("");
      const chosenProduct = products.find(p => p.name === e.target.value);
      const newEndDate = dayjs(dayjs(startDate).add(chosenProduct.days, "day"));
      const availableSpots = await getAvailability(
        chosenProduct.pmcId,
        getCorrectTime(startDate, fixedProductEndTime),
        getCorrectTime(newEndDate, fixedProductEndTime)
      );
      setEndDate(newEndDate);
      setAvailability(availableSpots);
      setPrice(chosenProduct.price);
    }
    startTimer();
    setProduct(e.target.value);
  };

  return (
    <div className="productsContainer formContainer">
      {isAfterAllowedTime(startDate, fixedProductEndTime) && !flexProduct ? (
        <p className="error">{`${
          t.initialInformation.afterAllowedTime
        } ${fixedProductEndTime}. ${
          t.initialInformation.chooseAnother
        } ${new Date(startDate).toLocaleDateString("sv")}`}</p>
      ) : (
        <>
          <h3>{t.initialInformation.selectProduct}</h3>
          <p>{t.common.vat}</p>{" "}
        </>
      )}
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          value={product}
          onChange={handleProductChange}
        >
          {flexProduct && (
            <div className="productContainer flexibleBookingContainer">
              <FormControlLabel
                value={flexProduct.name}
                control={<Radio />}
                label={flexProduct.name}
                className="productLabel"
              />
              {flexPrice ? (
                <p className="priceText">{`SEK ${flexPrice},00`}</p>
              ) : (
                flexPrice === 0 && (
                  <span className="error">{t.flexBooking.priceError}</span>
                )
              )}
              {product === flexProduct.name && (
                <FlexBookingPicker
                  title={t.flexBooking.flexText}
                  setFlexPrice={setFlexPrice}
                  flexProduct={flexProduct}
                />
              )}
            </div>
          )}
          {!isAfterAllowedTime(startDate, fixedProductEndTime) &&
            products.map((p, i) => {
              return (
                <div key={i} className="productContainer nonFlexContainer">
                  <FormControlLabel
                    value={p.name}
                    control={<Radio />}
                    label={p.name}
                    className="productLabel"
                  />
                  <p className="priceText">{`SEK ${p.price},00`}</p>
                  {product === p.name && (
                    <div className="productInfoContainer">
                      {availability ? (
                        <>
                          <p>
                            {`${t.initialInformation.parking} ${p.days} ${t.initialInformation.daysSpec1} 
                            ${t.initialInformation.clock} ${fixedProductEndTime} - 
                            ${t.initialInformation.clock} ${fixedProductEndTime} ${t.initialInformation.daysSpec2}`}
                          </p>
                        </>
                      ) : (
                        <div className="errorContainer">
                          <img alt="!" src={errorIcon} className="errorIcon" />

                          <p>{t.initialInformation.noAvailability}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ProductList;
