import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputContext, InputDispatchContext } from "../contexts/InputContext";
import { StepContext } from "../contexts/StepContext";
import { allProducts } from "../products";
import { getCorrectTime } from "../services/timeService";
import { DISCOUNTS } from "../utils/discounts";
import useApi from "../hooks/useApi";
import t from "../locales/sv.json";
import errorIcon from "../assets/svgs/errorIcon.svg";
import "../styles/Confirmation.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { isTimedOut } from "../services/timeOutService";

const Confirmation = () => {
  const [discountStatus, setDiscountStatus] = useState(DISCOUNTS.NONE);
  const [discountCode, setDiscountCode] = useState("");
  const [verifiedDiscount, setVerifiedDiscount] = useState(0);
  const [registering, setRegistering] = useState(false);
  const [error, setError] = useState("");
  const {
    product,
    price,
    email,
    firstName,
    lastName,
    countryCode,
    startDate,
    startTime,
    endDate,
    endTime,
    phoneNumber,
    licensePlate,
    facility,
  } = useContext(InputContext);
  const { lockBooking, registerPayment, verifyCampaignCode } = useApi();
  const { setStep } = useContext(StepContext);
  const { setStorage, setProduct } = useContext(InputDispatchContext);
  const navigate = useNavigate();

  const alphaNumericalHyphenRegEx = /[^a-zA-ZäöåÄÖÅ0-9-]+/;

  const totalAmount = price;

  const startTransaction = async () => {
    if (isTimedOut()) {
      navigate("/", { state: { timeOut: true } });
      setProduct(null);
      return;
    }
    setRegistering(true);
    setError("");
    const chosenProduct = allProducts.find(p => p.name === product);
    const validity = chosenProduct.isFlexible
      ? {
          validFrom: new Date(getCorrectTime(startDate, startTime)),
          validTo: new Date(getCorrectTime(endDate, endTime)),
        }
      : { validFrom: new Date(getCorrectTime(startDate, startTime)) };
    const lockBookingResult = await lockBooking(
      chosenProduct.pmcId,
      {
        email: email,
        phoneNumber: phoneNumber,
        sendEmailReceipt: true,
        firstName: firstName,
        lastName: lastName,
      },
      [{ plateText: licensePlate, countryCode }],
      validity
    );
    if (!lockBookingResult || lockBookingResult === 409) {
      setError(
        lockBookingResult === 409
          ? t.errors.bookingConflict
          : t.errors.lockBookingError
      );
      setRegistering(false);
      return;
    }
    const registerResponse = await registerPayment(
      chosenProduct.pmcId,
      getCorrectTime(startDate, startTime),
      getCorrectTime(endDate, endTime),
      discountCode,
      `${chosenProduct.parkingZoneId} ${chosenProduct.parkingFacilityName}`,
      chosenProduct.isFlexible,
      facility,
      startDate
    );
    if (!registerResponse.link) {
      setError(t.errors.netaxeptError);
      setRegistering(false);
      return;
    }
    setStorage(
      totalAmount - ((parseInt(verifiedDiscount) | 0) / 100) * totalAmount,
      lockBookingResult,
      registerResponse.orderNumber
    );
    window.open(registerResponse.link, "_parent");
  };

  const handleApplyDiscount = async e => {
    e.preventDefault();
    const discount = await verifyCampaignCode(
      discountCode,
      facility,
      startDate
    );

    setVerifiedDiscount(discount);
    if (discount === 0) {
      setDiscountStatus(DISCOUNTS.INVALID);
    } else if (discount === 100) {
      setDiscountStatus(DISCOUNTS.INTERNAL);
    } else {
      setDiscountStatus(DISCOUNTS.VALID);
    }
  };

  return (
    <div className="confirmationContainer">
      <h3>{t.confirmation.overview}</h3>
      <TableContainer className="tableContainer" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t.confirmation.product}</TableCell>
              <TableCell align="right">{t.confirmation.qty}</TableCell>
              <TableCell align="right">{t.common.vat}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              className="tableRow"
              key={product}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {product}
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">{price} kr</TableCell>
            </TableRow>

            {discountStatus !== DISCOUNTS.INVALID &&
              discountStatus !== DISCOUNTS.NONE && (
                <TableRow
                  className="discountRow"
                  key="discount"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t.confirmation.promotion}
                  </TableCell>
                  <TableCell align="right">
                    -{verifiedDiscount.toString()}%
                  </TableCell>
                  <TableCell align="right">
                    -{(totalAmount * parseInt(verifiedDiscount)) / 100} kr
                  </TableCell>
                </TableRow>
              )}
            <TableRow>
              <TableCell className="boldText" component="th" scope="row">
                {t.confirmation.total}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell className="boldText" align="right">
                {discountStatus
                  ? totalAmount -
                    (parseInt(verifiedDiscount) / 100) * totalAmount
                  : totalAmount}{" "}
                kr
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="discountContainer">
        <h3>{t.confirmation.promotion}</h3>
        <p>{t.confirmation.promotionExplanation}</p>
        <div className="discountField">
          <form onSubmit={handleApplyDiscount}>
            <TextField
              id="discount-input"
              label={t.confirmation.promotion}
              value={discountCode}
              error={discountStatus === DISCOUNTS.INVALID}
              helperText={
                discountStatus === DISCOUNTS.INVALID
                  ? t.confirmation.promotionError
                  : ""
              }
              disabled={registering}
              onChange={e =>
                setDiscountCode(
                  e.target.value.replace(alphaNumericalHyphenRegEx, "")
                )
              }
            />
            <Button
              type="submit"
              className="discountButton"
              variant="contained"
            >
              {t.confirmation.apply}
            </Button>
          </form>
        </div>
      </div>
      {error && (
        <div className="errorContainer">
          <img src={errorIcon} alt="!" className="errorIcon" />
          <p className="error">{error}</p>
        </div>
      )}
      <div className="submitContainer">
        <Button onClick={() => setStep(2)} variant="contained" color="primary">
          <Link className="routerLink" to="/personalInformation">
            {t.common.previousStep}
          </Link>
        </Button>
        {
          <Button
            variant="contained"
            color="primary"
            className="nextButton"
            onClick={startTransaction}
          >
            {!registering ? (
              t.confirmation.confirm
            ) : (
              <div className="smallLoader"></div>
            )}
          </Button>
        }
      </div>
    </div>
  );
};

export default Confirmation;
