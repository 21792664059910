import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ProductList from "./ProductList";
import { InputContext, InputDispatchContext } from "../contexts/InputContext";
import { StepContext } from "../contexts/StepContext";
import { facilities } from "../products";
import t from "../locales/sv.json";
import "../styles/InitialInformation.css";
import dayjs from "dayjs";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { isTimedOut } from "../services/timeOutService";

const InitialInformation = () => {
  const { facility, startDate, endDate, product, price, availability } =
    useContext(InputContext);
  const { setStartDate, setFacility, setProduct, setStartTime, setEndTime } =
    useContext(InputDispatchContext);
  const { setStep } = useContext(StepContext);

  const [timedOut, setTimedOut] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isTimedOut()) {
      setTimedOut(true);
      window.sessionStorage.clear();
    }
    const urlFacility = facilities.find(
      f => f.facilityId === searchParams.get("facilityId")
    );
    if (urlFacility) {
      setFacility(urlFacility.name);
    }
    setStep(1);
  }, [searchParams, setFacility, setStep]);

  const handleNextStep = step => {
    console.log(startDate);
    if (isTimedOut()) {
      window.location.reload();
    } else {
      setStep(step);
    }
  };

  const handleFacilityChange = async e => {
    setTimedOut(false);
    setFacility(e.target.value);
    if (e.target.value === "Mitt i Åre 1 - Kabinbanevägen 22") {
      setStartTime("15:00");
      setEndTime("15:00");
    } else {
      setStartTime("11:00");
      setEndTime("11:00");
    }
  };

  const handleStartDateChange = selectedDate => {
    if (dayjs(selectedDate).isSame(dayjs(), "day")) {
      setStartDate(dayjs());
    } else {
      setStartDate(dayjs(selectedDate));
    }
    setProduct(null);
  };

  const renderProducts = () => {
    const chosenFacility = facilities.find(f => f.name === facility);
    return (
      <ProductList
        products={chosenFacility.products}
        flexProduct={chosenFacility.flexProduct}
        fixedProductEndTime={chosenFacility.fixedProductEndTime}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="facilityInformationContainer">
        {timedOut && <p className="error">{t.errors.timedOut}</p>}
        <div className="formContainer">
          <FormControl fullWidth>
            <InputLabel id="selectLabel">
              {t.initialInformation.chooseFacility}
            </InputLabel>
            <Select
              onChange={handleFacilityChange}
              labelId="selectLabel"
              name="os"
              label="Välj anläggning"
              value={facility}
            >
              {facilities.map((f, i) => {
                return (
                  <MenuItem
                    onChange={handleFacilityChange}
                    key={i}
                    value={f.name}
                  >
                    {f.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <DatePicker
              label={t.initialInformation.arrival}
              inputFormat="YYYY/MM/DD"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={params => <TextField {...params} />}
              disablePast
            />
          </FormControl>
        </div>
        {facility && startDate && renderProducts()}
        <div className="submitContainer initial">
          <Button
            disabled={
              !facility ||
              !startDate ||
              !endDate ||
              !product ||
              !price ||
              !availability
            }
            variant="contained"
            color="primary"
            className="nextButton"
            onClick={() => handleNextStep(2)}
          >
            <Link
              className="routerLink"
              to={isTimedOut() ? "/" : "/personalInformation"}
            >
              {t.common.nextStep}
            </Link>
          </Button>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default InitialInformation;
