import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import FailedAnimation from "./FailedAnimation";
import ConfirmedAnimation from "./ConfirmedAnimation";
import { StepContext } from "../contexts/StepContext";
import "../styles/CancelledBooking.css";
import t from "../locales/sv.json";

const CancelledBooking = () => {
  const { setStep } = useContext(StepContext);
  const { state } = useLocation();
  const { error } = state;
  return (
    <div className="cancelledBookingContainer">
      {error ? (
        <div className="cancelledBookingErrorContainer">
          <FailedAnimation />

          <h2>{t.cancelledBooking.failedTitle}</h2>
          <p>
            {t.cancelledBooking.failedText1}
            <a
              className="emailLink"
              href={`mailto:${t.common.customerService}`}
            >
              {t.common.customerService}
            </a>
            {t.cancelledBooking.failedText2}
          </p>
        </div>
      ) : (
        <>
          <ConfirmedAnimation />
          <h2>{t.cancelledBooking.successTitle}</h2>
          <p>{t.cancelledBooking.successText}</p>
          <div className="homeLinkContainer">
            <Link to="/" className="homeLink" onClick={() => setStep(1)}>
              {t.finalInformation.back}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default CancelledBooking;
