import aimoLogo from "../assets/svgs/APLogo.svg";
import "../styles/AimoHeader.css";

const AimoHeader = () => {
  return (
    <div className="aimoHeader">
      <img alt="Aimo Park" src={aimoLogo}></img>
    </div>
  );
};

export default AimoHeader;
