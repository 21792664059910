import React, { createContext, useState } from "react";
import { facilities } from "../products";

const InputContext = createContext(undefined);
const InputDispatchContext = createContext(undefined);

function InputProvider({ children }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [facility, setFacility] = useState("");
  const [product, setProduct] = useState(null);
  const [startTime, setStartTime] = useState("11:00");
  const [endTime, setEndTime] = useState("11:00");
  const [price, setPrice] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [availability, setAvailability] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("S");

  const isInitialInfoMissing = () => {
    return !facility || !price || !availability || !product;
  };

  const getFacility = (facilityName) => {
    return facilities.find((f) => f.name === facilityName);
  };

  const setStorage = (totalAmount, permitId, orderNumber) => {
    window.sessionStorage.setItem("facility", facility);
    window.sessionStorage.setItem("startTime", startTime);
    window.sessionStorage.setItem("endTime", endTime);
    window.sessionStorage.setItem("startTime", startTime);
    window.sessionStorage.setItem("startDate", startDate);
    window.sessionStorage.setItem("endDate", endDate);
    window.sessionStorage.setItem("totalAmount", totalAmount);
    window.sessionStorage.setItem("permitId", permitId);
    window.sessionStorage.setItem("orderNumber", orderNumber);
  };

  const getStorage = () => {
    const facility = window.sessionStorage.getItem("facility");
    const startTime = window.sessionStorage.getItem("startTime");
    const endTime = window.sessionStorage.getItem("endTime");
    const startDate = window.sessionStorage.getItem("startDate");
    const endDate = window.sessionStorage.getItem("endDate");
    const totalAmount = window.sessionStorage.getItem("totalAmount");
    const permitId = window.sessionStorage.getItem("permitId");
    const orderNumber = window.sessionStorage.getItem("orderNumber");
    return {
      facility,
      startTime,
      endTime,
      startDate,
      endDate,
      totalAmount,
      permitId,
      orderNumber,
    };
  };

  return (
    <InputContext.Provider
      value={{
        facility,
        startDate,
        endDate,
        product,
        startTime,
        endTime,
        price,
        firstName,
        lastName,
        email,
        licensePlate,
        availability,
        phoneNumber,
        countryCode,
      }}
    >
      <InputDispatchContext.Provider
        value={{
          setEndDate,
          setFacility,
          setProduct,
          setStartDate,
          setStartTime,
          setEndTime,
          setPrice,
          setFirstName,
          setLastName,
          setEmail,
          setLicensePlate,
          setAvailability,
          setPhoneNumber,
          setCountryCode,
          setStorage,
          getStorage,
          isInitialInfoMissing,
          getFacility,
        }}
      >
        {children}
      </InputDispatchContext.Provider>
    </InputContext.Provider>
  );
}

export { InputProvider, InputContext, InputDispatchContext };
