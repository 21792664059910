import { useContext } from "react";
import { InputContext, InputDispatchContext } from "../contexts/InputContext";
import {
  roundMinutes,
  isValidTime,
  getCorrectTime,
} from "../services/timeService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Button } from "@mui/material";
import errorIcon from "../assets/svgs/errorIcon.svg";
import t from "../locales/sv.json";
import useApi from "../hooks/useApi";
import dayjs from "dayjs";

const FlexBookingPicker = ({ title, setFlexPrice, flexProduct }) => {
  const { availability, endDate, startDate, startTime, endTime } =
    useContext(InputContext);
  const { setStartTime, setEndTime, setPrice, setEndDate, setAvailability } =
    useContext(InputDispatchContext);
  const { getAvailability, getFlexiblePrice } = useApi();

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleCalculate = async () => {
    setStartTime(roundMinutes(startTime));
    setEndTime(roundMinutes(endTime));
    const availableSpots = await getAvailability(
      flexProduct.pmcId,
      getCorrectTime(startDate, startTime),
      getCorrectTime(endDate, endTime)
    );
    setAvailability(availableSpots);
    if (availableSpots > 0) {
      const updatedPrice = await getFlexiblePrice(
        flexProduct.pmcId,
        getCorrectTime(startDate, startTime),
        getCorrectTime(endDate, endTime)
      );
      setFlexPrice(parseInt(updatedPrice));
      setPrice(parseInt(updatedPrice));
    }
  };

  return (
    <>
      {availability === 0 && (
        <div className="errorContainer">
          <img alt="!" src={errorIcon} className="errorIcon" />

          <p>{t.initialInformation.noAvailability}</p>
        </div>
      )}
      <div>
        <p>{title}</p>

        <p>{t.flexBooking.chooseDates}</p>
        <div className="startTimeContainer">
          <p>{new Date(startDate).toLocaleDateString("sv", dateOptions)}</p>
          <TextField
            error={
              !isValidTime(startTime) ||
              (dayjs(startDate).isSame(dayjs(endDate), "day") &&
                parseInt(endTime) < parseInt(startTime))
            }
            helperText={
              !isValidTime(startTime)
                ? t.flexBooking.formatError
                : dayjs(startDate).isSame(dayjs(endDate), "day") &&
                  parseInt(endTime) < parseInt(startTime)
                ? t.flexBooking.earlyDepartureError
                : ""
            }
            placeholder="HH:MM"
            onChange={e => setStartTime(e.target.value)}
            className="fixedWidthInput"
            value={startTime}
          />
        </div>

        <p>{t.flexBooking.departureSelect}</p>
        <div className="endTimeContainer">
          <DatePicker
            label={t.flexBooking.departure}
            value={endDate}
            onChange={date => setEndDate(dayjs(date))}
            disablePast
            inputFormat="YYYY/MM/DD"
            renderInput={params => (
              <TextField
                {...params}
                error={dayjs(startDate).isAfter(endDate, "day")}
                helperText={
                  dayjs(startDate).isAfter(endDate, "day") &&
                  t.flexBooking.departureError
                }
                className="fixedWidthInput"
              />
            )}
          />
          <TextField
            error={
              !isValidTime(endTime) ||
              (dayjs(startDate).isSame(dayjs(endDate), "day") &&
                parseInt(endTime) < parseInt(startTime))
            }
            helperText={
              !isValidTime(endTime)
                ? t.flexBooking.formatError
                : dayjs(startDate).isSame(dayjs(endDate), "day") &&
                  parseInt(endTime) < parseInt(startTime)
                ? t.flexBooking.earlyDepartureError
                : ""
            }
            placeholder="HH:MM"
            className="fixedWidthInput"
            onChange={e => setEndTime(e.target.value)}
            value={endTime}
          />
        </div>
      </div>
      <div className="submitContainer">
        <Button
          className="calculateButton"
          disabled={
            !endTime ||
            !startTime ||
            !isValidTime(startTime) ||
            !isValidTime(endTime) ||
            !endDate ||
            (startTime === endTime && dayjs(startDate).isSame(endDate, "day"))
          }
          onClick={handleCalculate}
          variant="contained"
        >
          {t.flexBooking.calculate}
        </Button>
      </div>
    </>
  );
};

export default FlexBookingPicker;
