import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { InputContext, InputDispatchContext } from "../contexts/InputContext";
import { countryCodes } from "../countryCodes";
import t from "../locales/sv.json";
import "../styles/PersonalInformation.css";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { StepContext } from "../contexts/StepContext";
import { isTimedOut } from "../services/timeOutService";

const PersonalInformation = () => {
  const { firstName, lastName, email, licensePlate, phoneNumber, countryCode } =
    useContext(InputContext);
  const {
    setFirstName,
    setLastName,
    setLicensePlate,
    setEmail,
    setPhoneNumber,
    setCountryCode,
  } = useContext(InputDispatchContext);
  const { setStep } = useContext(StepContext);
  const [policyAgreed, setPolicyAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [secondEmail, setSecondEmail] = useState("");
  const [emailsNotMatching, setEmailsNotMatching] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const alphaRegEx = /[^a-zA-ZäöåÄÖÅ]+/;
  const alphaNumericalRegEx = /[^a-zA-ZäöåÄÖÅ0-9]+/;
  const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const isNextStepDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !licensePlate ||
    !termsAgreed ||
    !policyAgreed ||
    !secondEmail ||
    !phoneNumber;

  const isValidPhoneNumber = (number) => {
    const regEx = /\+[0-9]+$/;
    return regEx.test(number);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.replace(" ", "");
    setEmail(emailValue);
    if (emailValue !== secondEmail && secondEmail) {
      setEmailsNotMatching(true);
    } else {
      setEmailsNotMatching(false);
    }
    setIsValidEmail(emailRegEx.test(emailValue));
  };

  const handleVerifyEmails = (e) => {
    const emailValue = e.target.value.replace(" ", "");
    setSecondEmail(emailValue);
    setEmailsNotMatching(emailValue !== email);
  };

  const handleNextStep = (step) => {
    if (isTimedOut()) {
      window.location.reload();
    } else {
      setStep(step);
    }
  };

  return (
    <>
      <div className="personalInformationContainer">
        <h3>{t.personalInformation.details}</h3>
        <div className="firstNameContainer">
          <p>{t.personalInformation.firstName}</p>
          <TextField
            id="personal-info-first-name"
            label={t.personalInformation.firstName}
            value={firstName}
            onChange={(e) =>
              setFirstName(e.target.value.replace(alphaRegEx, ""))
            }
          />
        </div>
        <div className="lastNameContainer">
          <p>{t.personalInformation.lastName}</p>
          <TextField
            id="personal-info-last-name"
            label={t.personalInformation.lastName}
            value={lastName}
            onChange={(e) =>
              setLastName(e.target.value.replace(alphaRegEx, ""))
            }
          />
        </div>
        <div className="phoneNumberContainer">
          <p>{t.personalInformation.phone}</p>
          <TextField
            id="personal-info-phone-number"
            label={t.personalInformation.phone}
            value={phoneNumber}
            error={!isValidPhoneNumber(phoneNumber) && phoneNumber !== ""}
            helperText={
              phoneNumber &&
              !isValidPhoneNumber(phoneNumber) &&
              t.personalInformation.invalidPhone
            }
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="emailContainer">
          <p>{t.personalInformation.email}</p>
          <TextField
            id="personal-info-email1"
            label={t.personalInformation.email}
            value={email}
            error={!isValidEmail}
            helperText={!isValidEmail ? t.personalInformation.invalidEmail : ""}
            onChange={handleEmailChange}
          />
        </div>
        <div className="emailContainer">
          <p>{t.personalInformation.repeatEmail}</p>
          <TextField
            id="personal-info-email2"
            label={t.personalInformation.email}
            error={emailsNotMatching}
            helperText={
              emailsNotMatching ? t.personalInformation.emailsNotMatching : ""
            }
            value={secondEmail}
            onChange={handleVerifyEmails}
          />
        </div>
        <div>
          <h3>{t.personalInformation.access}</h3>
          <p>{t.common.regPlate}</p>

          <div className="licensePlateContainer">
            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              {countryCodes.map((f) => {
                return (
                  <MenuItem key={f} value={f}>
                    {f}
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              id="personal-info-license-plate"
              label={t.common.regPlate}
              value={licensePlate}
              onChange={(e) =>
                setLicensePlate(e.target.value.replace(alphaNumericalRegEx, ""))
              }
            />
          </div>
        </div>
        <div className="conditionsContainer">
          <h3>{t.personalInformation.terms}</h3>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={() => setTermsAgreed(!termsAgreed)}
                />
              }
              label={
                <a
                  href="https://www.aimopark.se/kundservice/avtalsvillkor/"
                  target="_blank"
                  rel="noreferrer"
                  className="termsLink"
                >
                  {t.personalInformation.termsAndConditions}
                </a>
              }
            ></FormControlLabel>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={policyAgreed}
                  onChange={() => setPolicyAgreed(!policyAgreed)}
                />
              }
              label={
                <a
                  href="https://www.aimopark.se/om-aimo-park/om-webbplatsen/integritetspolicy/"
                  target="_blank"
                  rel="noreferrer"
                  className="termsLink"
                >
                  {t.personalInformation.privacy}
                </a>
              }
            ></FormControlLabel>
          </FormControl>
        </div>
        <div className="submitContainer">
          <Button
            onClick={() => handleNextStep(1)}
            variant="contained"
            color="primary"
          >
            <Link className="routerLink" to="/">
              {t.common.previousStep}
            </Link>
          </Button>
          <Button
            onClick={() => handleNextStep(3)}
            variant="contained"
            color="primary"
            className="nextButton"
            disabled={isNextStepDisabled}
          >
            <Link
              className="routerLink"
              to={isTimedOut() ? "/" : "/confirmation"}
            >
              {t.common.nextStep}
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
};
export default PersonalInformation;
