export const tottProducts = [
  {
    name: "Tott House Garage - 1 dygn",
    price: 100,
    days: 1,
    pmcId: "9851-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 2 dygn",
    price: 200,
    days: 2,
    pmcId: "9850-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 3 dygn",
    price: 300,
    days: 3,
    pmcId: "9852-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 4 dygn",
    price: 400,
    days: 4,
    pmcId: "9853-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 5 dygn",
    price: 500,
    days: 5,
    pmcId: "9854-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 6 dygn",
    price: 600,
    days: 6,
    pmcId: "9855-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
  {
    name: "Tott House Garage - 7 dygn",
    price: 700,
    days: 7,
    pmcId: "9857-PB",
    parkingFacilityUid: 63145,
    parkingZoneId: "SE-6314501",
    parkingFacilityName: "Brf Tott House, garage",
    isFlexible: false,
  },
];

export const kabinProducts = [
  {
    name: "1 dygn, oreserverad nr 8 - 37",
    price: 120,
    days: 1,
    pmcId: "4a7f5a53-7e91-4021-9918-791998c714c0",
    parkingZoneId: "SE-6351901",
    parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
    isFlexible: false,
  },
  {
    name: "2 dygn, oreserverad nr 8 - 37",
    price: 230,
    days: 2,
    pmcId: "06320d27-8709-4d53-9185-8e2270c9e7d7",
    parkingZoneId: "SE-6351901",
    parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
    isFlexible: false,
  },
  {
    name: "3 dygn, oreserverad nr 8 - 37",
    price: 340,
    days: 3,
    pmcId: "95609895-f923-4ff4-a08e-6ea8d414b5c6",
    parkingZoneId: "SE-6351901",
    parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
    isFlexible: false,
  },
  {
    name: "4 dygn, oreserverad nr 8 - 37",
    price: 440,
    days: 4,
    pmcId: "01673320-ab35-484d-b073-aad26cdbcc9b",
    parkingZoneId: "SE-6351901",
    parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
    isFlexible: false,
  },
  {
    name: "7 dygn, oreserverad nr 8 - 37",
    price: 700,
    days: 7,
    pmcId: "b6010234-9ad2-4f20-a905-b35d534e38d1",
    parkingZoneId: "SE-6351901",
    parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
    isFlexible: false,
  },
];

export const tottFlexProduct = {
  name: "Flexbokning",
  price: null,
  days: null,
  pmcId: "10962-PB",
  parkingZoneId: "SE-6314501",
  parkingFacilityName: "Brf Tott House, garage",
  isFlexible: true,
};

export const kabinFlexProduct = {
  name: "Besöksparkering, oreserverad nr 46 - 49",
  price: null,
  days: null,
  pmcId: "12164-PB",
  parkingZoneId: "SE-6325401",
  parkingFacilityName: "Kabinbanevagen 22",
  isFlexible: true,
};

export const kabinFlexProduct2 = {
  name: "Flexbokning Oreserverad nr 8 - 37",
  price: null,
  days: null,
  pmcId: "ec858ccd-7cd4-46e7-9bb3-f9f66155df14",
  parkingZoneId: "SE-6351901",
  parkingFacilityName: "Mitt i Are - Kabinbanevagen 22",
  isFlexible: true,
};

export const testProducts = [
  {
    name: "1 Dygn - Förboka X",
    price: 10,
    days: 1,
    pmcId: "efad3b40-9dad-4996-81eb-d49af6a29320",
    parkingZoneId: "SE-9999989",
    isFlexible: false,
  },
];

export const facilities = [
  {
    name: "Mitt i Åre 1 - Kabinbanevägen 22",
    address: "Kabinbanevägen 22",
    zipCode: "83752",
    city: "Åre",
    link: "https://www.aimopark.se/stader/are/kabinbanevagen-22/",
    description: "",
    garageDescription:
      "Detta är en uteparkering med oreserverade parkeringsplatser. Parkering får endast ske på valfri ledig plats med nr 8 till och med plats nr 37.",
    products: kabinProducts,
    flexProduct: kabinFlexProduct2,
    fixedProductEndTime: "15:00",
    facilityId: "SE-63519",
  },
  {
    name: "Kabinbanevägen 22",
    address: "Kabinbanevägen 22E",
    zipCode: "83752",
    city: "Åre",
    link: "https://www.aimopark.se/stader/are/kabinbanevagen-22e/",
    description:
      "Detta är en uteparkering med oreserverade parkeringsplatser. Platserna ligger uppe på taket (plats 46-49), Hotell Åre Torg. ",
    garageDescription:
      "Parkering får endast ske på valfri ledig plats med nr 46 till och med plats nr 49.",
    products: [],
    flexProduct: kabinFlexProduct,
    fixedProductEndTime: "",
    facilityId: "SE-63254",
  },
  {
    name: "Brf Tott House, garage",
    address: "Tottvägen 113",
    zipCode: "83751",
    city: "Åre",
    link: "https://www.aimopark.se/stader/are/brf-tott-house-garage/",
    description:
      "Garaget har oreserverade parkeringsplatser, vilket innebär att du får ett digitalt tillstånd som är kopplat till det regnummer du angav under bokningen. Parkering sker på de platser som är lediga vid varje parkeringstillfälle.",
    garageDescription:
      "Garaget är alltid låst och för att komma in i garaget behöver du ange din kod. Ange kod: 1376. När grön lampa lyser öppnas garageporten, kör in och parkera på valfri ledig p-plats. Vid utfart från garaget behöver du öppna porten genom att trycka på 'upp' knappen som sitter inne i garaget.",
    products: tottProducts,
    flexProduct: tottFlexProduct,
    fixedProductEndTime: "11:00",
    facilityId: "SE-63145",
  },
];

export const allProducts = [
  ...tottProducts,
  ...kabinProducts,
  tottFlexProduct,
  kabinFlexProduct,
  kabinFlexProduct2,
];
