import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { InputDispatchContext } from "../contexts/InputContext";
import { StepContext } from "../contexts/StepContext";
import useApi from "../hooks/useApi";
import t from "../locales/sv.json";
import "../styles/ProcessingPayment.css";
import { getCorrectTime } from "../services/timeService";

const CONFIRMATION_URL = "/order/finalInformation";

const ProcessingPayment = () => {
  const {
    sendConfirmationEmail,
    processPayment,
    getPermit,
    processInternalBooking,
  } = useApi();
  const { setStep } = useContext(StepContext);
  const { getStorage, getFacility } = useContext(InputDispatchContext);
  const [searchParams] = useSearchParams();

  const {
    facility,
    startTime,
    endTime,
    startDate,
    endDate,
    totalAmount,
    permitId,
    orderNumber,
  } = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const doPayment = async () => {
      const transactionId = searchParams.get("transactionId");
      const discountCode = searchParams.get("discountCode");
      try {
        const permit = await getPermit(permitId);
        const result = discountCode
          ? await processInternalBooking(
              discountCode,
              permitId,
              facility,
              startDate
            )
          : await processPayment(transactionId, permitId);
        const chosenFacility = getFacility(facility);
        if (result.data) {
          await sendConfirmationEmail(
            permit.customer.email,
            permit.customer.name,
            facility,
            chosenFacility.address,
            chosenFacility.description,
            chosenFacility.garageDescription,
            permit.product,
            startTime,
            endTime,
            getCorrectTime(startDate, startTime),
            getCorrectTime(endDate, endTime),
            totalAmount,
            permit.licensePlate,
            transactionId,
            permitId,
            orderNumber
          );
        }
        navigate(CONFIRMATION_URL, {
          state: {
            error: result.error,
            status: result.status,
            facility: chosenFacility.name,
            address: chosenFacility.address,
            startTime,
            endTime,
            startDate,
            endDate,
            name: permit.customer.name,
            email: permit.customer.email,
            licensePlate: permit.licensePlate,
            orderNumber,
          },
        });
      } catch (e) {
        navigate(CONFIRMATION_URL, {
          state: {
            error: t.errors.genericError,
          },
        });
      }
    };
    const responseCode = searchParams.get("responseCode");
    setStep(4);
    if (responseCode === "OK") {
      doPayment();
    } else if (responseCode === "Cancel") {
      navigate(CONFIRMATION_URL, {
        state: { error: t.errors.transactionCancelled },
      });
    } else {
      navigate(CONFIRMATION_URL, {
        state: {
          error: t.errors.transactionError,
        },
      });
    }
    window.sessionStorage.clear();
  }, []);
  return searchParams.get("transactionId") ? (
    <div className="processingPaymentContainer">
      <h2>{t.processingPayment.processing}</h2>
      <div>
        <p>{t.processingPayment.warning}</p>
      </div>

      <div className="loader"></div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default ProcessingPayment;
