import { Link } from "@mui/material";
import "../styles/Footer.css";
import t from "../locales/sv.json";
import greenLogo from "../assets/svgs/APLogoGreen.svg";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerLinkContainer">
        <p>© 1998-2023 Aimo Park Sweden AB</p>

        <Link
          href="https://www.aimopark.se/om-aimo-park/om-webbplatsen/integritetspolicy/"
          target="_blank"
          rel="noreferrer"
        >
          {t.footer.privacy}
        </Link>

        <Link
          href="https://www.aimopark.se/kundservice/avtalsvillkor/"
          target="_blank"
          rel="noreferrer"
        >
          {t.footer.terms}
        </Link>

        <Link href="https://www.aimopark.se/" target="_blank" rel="noreferrer">
          AimoPark.se
        </Link>
      </div>
      <div className="footerLogoContainer">
        <img src={greenLogo} alt="Aimo Park" />
      </div>
    </div>
  );
};

export default Footer;
