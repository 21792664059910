export const countryCodes = [
  "S",
  "A",
  "ANG",
  "AUS",
  "AZ",
  "B",
  "BG",
  "BH",
  "BIH",
  "BR",
  "BY",
  "CAN",
  "CH",
  "CHN",
  "CO",
  "CZ",
  "D",
  "DK",
  "DZ",
  "E",
  "EAK",
  "EC",
  "EG",
  "EST",
  "F",
  "FIN",
  "FL",
  "GB",
  "GE",
  "GR",
  "H",
  "HK",
  "HR",
  "I",
  "IL",
  "IND",
  "IRL",
  "ISl",
  "KSA",
  "KWT",
  "KZ",
  "L",
  "LIB",
  "LT",
  "LV",
  "MAC",
  "MAR",
  "MC",
  "MEX",
  "MK",
  "MNE",
  "MS",
  "MY",
  "N",
  "NGR",
  "NL",
  "NZL",
  "OM",
  "P",
  "PE",
  "PL",
  "QA",
  "RA",
  "RCH",
  "RO",
  "ROU",
  "RUS",
  "SK",
  "SLO",
  "TR",
  "UA",
  "UAE",
  "USA",
  "YU",
  "YV",
  "ZA",
];
