import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import InitialLayout from "./layouts/InitialLayout ";
import InitialInformation from "./components/InitialInformation";
import PersonalInformation from "./components/PersonalInformation";
import Confirmation from "./components/Confirmation";
import FinalInformation from "./components/FinalInformation";
import ScrollToTop from "./components/ScrollToTop";
import { InputDispatchContext } from "./contexts/InputContext";
import ProcessingPayment from "./components/ProcessingPayment";
import Cancellation from "./components/Cancellation";
import CancelledBooking from "./components/CancelledBooking";
import OrderConfirmedLayout from "./layouts/OrderConfirmedLayout";

function App() {
  const { isInitialInfoMissing } = useContext(InputDispatchContext);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/order" element={<OrderConfirmedLayout />}>
          <Route path="cancellation" element={<Cancellation />} />
          <Route path="cancellationConfirmed" element={<CancelledBooking />} />
          <Route path="finalInformation" element={<FinalInformation />} />
        </Route>
        <Route path="/" element={<InitialLayout />}>
          <Route index element={<InitialInformation />} />
          <Route
            path="personalInformation"
            element={
              isInitialInfoMissing() ? (
                <Navigate to="/" />
              ) : (
                <PersonalInformation />
              )
            }
          />
          <Route
            path="confirmation"
            element={
              isInitialInfoMissing() ? <Navigate to="/" /> : <Confirmation />
            }
          />
          <Route path="processingPayment" element={<ProcessingPayment />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
